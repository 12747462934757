import { Stack, Box } from "@mui/material";
import portrait from "../media/portrait.jpeg";
import { LocationOnOutlined, GitHub, LinkedIn } from '@mui/icons-material';
import AboutHeading from "./AboutHeading";

export default function Sidebar() {
    return (
        <Stack sx={{
            pl: 4
        }}>
            <Box
                sx={{
                    mt: '80%',
                    mr: 5,
                    ml: 5,
                }}
            >
                <img
                    src={portrait}
                    alt="portrait"
                    style={{ borderRadius: "50%" }}
                />
            </Box>
            <AboutHeading
                icon={<LocationOnOutlined />}
                displayText='Boise, Idaho'
            />
            <AboutHeading
                icon={<GitHub />}
                displayText='Github'
                link='https://github.com/blake-gifford'
            />
            <AboutHeading
                icon={<LinkedIn />}
                displayText='LinkedIn'
                link='https://www.linkedin.com/in/blake-gifford/'
            />
        </Stack >
    )
}