import { Stack, Box } from "@mui/material";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";

export default function App() {
  return (
    <Stack
      direction="row" >
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          grid: 8
        }}
        p={4}
        width="100%"
      >
        <Dashboard />
      </Box>
      <Box>
        <Sidebar />
      </Box>
    </Stack>
  )
}