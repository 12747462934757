import { GitHub } from "@mui/icons-material";
import { Card, CardContent, Grid, Link, Typography } from "@mui/material";

export default function ProjectCard({ heading, description, link }) {
    return (
        <Grid xs={12}>
            <Link
                href={link}
                sx={{
                    textDecoration: 'none',
                    color: '#000000',
                    m: 1
                }}
            >
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {heading}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {description}
                        </Typography>
                        <GitHub />
                    </CardContent>
                </Card>
            </Link>
        </Grid>
    )
}