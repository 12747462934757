import { Grid, Stack, Typography } from "@mui/material";
import TechTag from "./TechTag";

export default function TechTags() {
    return (
        <Grid>

            <Typography>Main Skills</Typography>
            <Stack sx={{
                flexDirection: 'row',
                flexWrap: 'wrap'
            }}>

                <TechTag
                    label="React.js"
                />
                <TechTag
                    label="Node.js"
                />
                <TechTag
                    label="PostgreSQL"
                />
                <TechTag
                    label=".NET"
                />
                <TechTag
                    label="C#"
                />
                <TechTag
                    label="Python"
                />
                <TechTag
                    label="Go"
                />
            </Stack >


            <Typography>Other Tech</Typography>
            <Stack
                sx={{
                    flexDirection: 'row',
                    flexWrap: 'wrap'
                }}>
                <TechTag
                    label="Docker"
                />
                <TechTag
                    label="AWS"
                />
                <TechTag
                    label="Pandas"
                />
                <TechTag
                    label="SQL"
                />
                <TechTag
                    label="CI/CD"
                />
            </Stack>
        </Grid >
    )
}