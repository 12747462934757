import { Grid, Stack, Box } from "@mui/material";
import ProjectCard from "../components/ProjectCard";
import TechTags from "../components/TechTags";

export default function Dashboard() {
    return (
        <Grid
            sx={{
                flex: 'wrap',
            }}
            container
        >
            <Box
                sx={{
                    flex: 'wrap'
                }}
            >
                <TechTags />
                <Stack>
                    <ProjectCard
                        heading="Go API Tutorial"
                        description="Simple GOLang api application."
                        link="https://github.com/blake-gifford/goapi"
                    />
                    <ProjectCard
                        heading="Task Manager"
                        description="Simple GOLang api application."
                        link="https://github.com/blake-gifford/taskManagement"
                    />
                    <ProjectCard
                        heading=".NET Console App"
                        link="https://github.com/blake-gifford/csharp-console"
                    />
                    <ProjectCard
                        heading="Venue Voice"
                        link="https://github.com/blake-gifford/VenueVoice"
                    />
                    <ProjectCard
                        heading="Angular/.NET App"
                        link="https://github.com/blake-gifford/Dotnet-Angular"
                    />
                </Stack>
            </Box>
        </Grid >
    );
}