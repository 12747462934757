import { Chip } from "@mui/material";

export default function TechTag({ label }) {
    return (
        <>
            <Chip
                sx={{
                    m: 1,
                }}
                label={label}
            />
        </>
    )
}