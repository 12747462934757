import { Box, Link, Typography } from "@mui/material";
export default function AboutHeading({ icon, displayText, link }) {
    return (
        <Link
            href={link}
            sx={{
                textDecoration: 'none',
            }}
            color="#000000"
        >
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                pt: 2,
            }}>
                {icon}
                <Typography
                    sx={{
                        fontFamily: "Segoe UI"
                    }}
                    variant="subtitle1"
                >
                    {displayText}
                </Typography>
            </Box>
        </Link>
    )
}